.wrapper {
  position: absolute;
  bottom: 80px;
  left: 10px;
}

.container {
  display: flex;
  align-items: center;

  .block {
    padding: 5px 10px;
  }
  .executed {
    background-color: green;
  }

  .onExecution {
    background-color: blue;
  }

  .expired {
    background-color: red;
  }
}
