.radio_group {
  &_wrapper {
    padding-top: 15px;
  }
  &_button {
    background-color: transparent !important;
    border: none !important;
    box-shadow: none !important;
    color: #fff !important;
    border-radius: 0 !important;
    font-family: "IBM Plex Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    height: 100% !important;
    padding: 10px 16px !important;
  }

  :global(.ant-radio-button-wrapper-checked) {
    // border-bottom: 3px solid  !important;
    color: #2ba6ff !important;
    position: relative;
  }

  :global(.ant-radio-button-wrapper-checked):after {
    content: "";
    width: 60%;
    border-bottom: 3px solid #2ba6ff !important;
    position: absolute;
    right: 20%;
    bottom: 0px;
  }

  :global(.ant-radio-button-wrapper:not(:first-child)::before),
  :global(.ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled)::before) {
    width: 0 !important;
  }
}
