.singleNumber {
  text-align: center;

  .number {
    color: #fff;
    font-size: 28px;
    line-height: 1;
  }

  .title {
    color: #98a4b9;
    font-size: 16px;
    line-height: 1;
    margin-bottom: 6px;
  }
}
