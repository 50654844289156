.wrapper {
  margin: 15px;
  &_title {
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    padding: 10px;
    margin-bottom: 16px;
    border: 1px solid #40404D;
  }
}

.criminals_wrapper {
  font-size: 20px;
  font-weight: 500;
  margin-bottom: 10px;
}

.kazgidromet_wrapper {
  border: 1px solid #40404D;
  border-radius: 4px;
  padding: 10px 0 0;
  margin-bottom: 20px;
  &_title {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
  }
  &_content {
    padding: 20px;
    background-color: #2F2F39;
  }
  p {
    font-size: 12px;
    color: #CAE9FF;
    text-align: right;
    padding-right: 20px;
    margin-top: 20px;
  }
}