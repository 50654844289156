.card {
  display: flex;
  flex-direction: column;
  text-align: center;
  border: none;
  background-color: #2F2F39;
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.1);
  height: 100%;
  cursor: pointer;
  transition: transform 0.2s;
  position: relative;

  &:hover {
    background: #4f5d6e;
    transform: scale(1.1);
    box-shadow: 0 0 8px rgba(0, 0, 0, 0.3);
    z-index: 10;
  }

  .cardHeading {
    padding: 20px;

    .cardTitle {
      font-size: 14px;
      font-weight: 600;
      line-height: 1;
      color: #fff; //#98a4b9;
      margin-bottom: 0;
    }
  }

  .cardBody {
    flex: 1;
    display: flex;
    flex-direction: column;
    padding: 1.25rem;
    padding-top: 0;

    h2 {
      flex: 1;
      font-size: 30px;
      line-height: 1;

      small {
        font-size: 16px;
      }
    }

    p {
      color: #CAE9FF;
    }
  }
}
