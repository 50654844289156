.tab_main_title {
  font-family: "IBM Plex Sans";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 28px;
  color: #ffffff;

  margin-top: 24px;
}
