.crime_amount_wrapper {
  background-color: #2F2F39;
  border: 1px solid #40404D;
  border-radius: 4px;
  text-align: center;
  padding: 20px;
  margin-bottom: 20px;
  &_title {
    font-size: 16px;
    color: rgba(255, 255, 255, 0.6);
  }
  &_subtitle {
    font-size: 20px;
  }
}

.general_dynamics {
  border: 1px solid #40404D;
  border-radius: 4px;
  padding: 10px 0 0;
  margin-bottom: 20px;
  &_title {
    font-size: 14px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 10px;
  }
  &_content {
    height: 380px;
    padding: 20px 0;
    background-color: #2F2F39;
  }
  p {
    font-size: 12px;
    color: #CAE9FF;
    text-align: right;
    padding-right: 20px;
    margin-top: 10px;
  }
}